/* eslint-disable @typescript-eslint/no-floating-promises */
import GET_USER from "@/graphql/queries/user/getUser";
import normalizeUser from "@/model/User";
import { withoutNulls } from "@/utils/withoutNulls";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import * as Sentry from "@sentry/nextjs";
import { useAuth0 } from "@auth0/auth0-react";

export default function useCurrentUser() {
  const {
    isAuthenticated,
    isLoading: authLoading,
    user: auth0User,
  } = useAuth0();

  const {
    data: raw,
    loading: userLoading,
    error,
  } = useQuery(GET_USER, {
    skip: !isAuthenticated,
  });

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.user);
    return data && normalizeUser(data);
  }, [raw]);

  Sentry.setUser({ id: normalized?.id, email: normalized?.email });

  return {
    data: normalized,
    loading: authLoading || userLoading,
    error,
    auth0User,
    isAuthenticated,
  };
}
