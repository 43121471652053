/* eslint-disable @typescript-eslint/ban-types */
import { ReactElement, ReactNode, useState } from "react";
import { AppProps } from "next/app";
import DatadogProvider from "@/providers/DatadogProvider";
import { AnalyticsProvider } from "@/providers/AnalyticsProvider";
import { NextPage } from "next";
import { ThemeProvider } from "@emotion/react";
import defaultTheme from "@/styles/themes/default";
import "@/styles/globals.css";
import "@/styles/fonts.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import GraphQLProvider from "@/providers/GraphQlProvider";
import { Auth0Provider } from "@auth0/auth0-react";
import GraphQlErrorBoundary from "@/components/GraphQlErrorBoundary";
import { Toaster } from "react-hot-toast";
import { useRouter } from "next/router";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page: ReactNode) => page);
  const redirectUri =
    typeof window !== "undefined" ? window.location.origin : "";

  const [loginOccurred, setLoginOccurred] = useState(false);
  const router = useRouter();

  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL || ""}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ""}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
      }}
      onRedirectCallback={(appState) => {
        setLoginOccurred(true);
        window.sessionStorage.removeItem("eSigner");
        window.sessionStorage.removeItem("returnTo");

        if (appState?.returnTo) {
          void router.push(appState.returnTo);
        } else {
          void router.push("/");
        }
      }}
    >
      <GraphQlErrorBoundary>
        {(useLogoutError: () => void) => (
          <GraphQLProvider useLogoutError={useLogoutError}>
            <Toaster />
            <DatadogProvider />
            <AnalyticsProvider>
              <ThemeProvider theme={defaultTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {getLayout(
                    <Component {...pageProps} loginOccurred={loginOccurred} />
                  )}
                </LocalizationProvider>
              </ThemeProvider>
            </AnalyticsProvider>
          </GraphQLProvider>
        )}
      </GraphQlErrorBoundary>
    </Auth0Provider>
  );
}
